import Auction from "../components/Auction";
import { useState, useEffect } from "react";
import FormModal from "../components/FormModal";
import NewListingModal from "../components/NewListingModal";
import ByCommunities from "../components/ByCommunities";
import Head from "next/head";
import { useRouter } from "next/router";
import { webURL, serverAPI } from "../utils/envConfig";
import axios from "axios";
import dynamic from "next/dynamic";

const HomeBanner = dynamic(() => import("../components/HomeBanner"), {
  ssr: true,
});
const References = dynamic(() => import("../components/References"), {
  ssr: true,
});
const Blog = dynamic(() => import("../components/Blog"), { ssr: true });
const Deals = dynamic(() => import("../components/Deals"), { ssr: true });
const Featured = dynamic(() => import("../components/Featured"), {
  ssr: true,
});
const Footer = dynamic(() => import("../components/Footer"), { ssr: true });
const GetMatch = dynamic(() => import("../components/GetMatch"), {
  ssr: true,
});
const Newsletter = dynamic(() => import("../components/Newsletter"), {
  ssr: true,
});

const headers = {
  "Content-Type": "application/json",
};

const Home = ({ blogs, errors, developers }) => {
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showNewListing, setShowNewListing] = useState(false);

  useEffect(() => {
    if (router.pathname === "/new-listing") {
      setShowNewListing(true);
    }
  }, [router.pathname]);

  if (errors) {
    console.log("Error from getting blogs", errors);
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Head>
        <link
          rel="canonical"
          href={`${webURL}${router.pathname}`}
          key="canonical"
        />
        <meta
          name="keywords"
          content="Real Estate Company Dubai, Real Estat, Real Estate Agency, Real Estate Company UAE"
        />
        <title>
          Real Estate Company Dubai, UAE | Real Estate Agency | Alba Homes
        </title>
        <meta
          name="description"
          content="Alba Homes is a leading Real Estate Company in Dubai, UAE, offering a wide variety of properties including apartments and villas for buying, selling, or rental."
        />

        <meta
          property="og:title"
          content="Real Estate Company Dubai, UAE | Real Estate Agency | Alba Homes"
        />
        <meta property="og:site_name" content="Alba Homes" />
        <meta property="og:url" content={webURL + "/"} />
        <meta
          property="og:description"
          content="Alba Homes is a leading Real Estate Company in Dubai, UAE, offering a wide variety of properties including apartments and villas for buying, selling, or rental."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${webURL}/img/alba_homes_og_image.webp`}
        />
      </Head>
      <>
        <HomeBanner
          showGetMatched={() => setShowModal(true)}
          showNewListingForm={() => setShowNewListing(true)}
        />

        <GetMatch showModal={showModal} setShowModal={setShowModal} />

        <FormModal showModal={showFormModal} setShowModal={setShowFormModal} />

        <NewListingModal
          showNewListing={showNewListing}
          setShowNewListing={setShowNewListing}
        />

        <References developers={developers} />

        <Featured />

        <Auction showForm={() => setShowNewListing(true)} />
        <ByCommunities />

        <Deals />

        <Blog blogs={blogs} />
        <Newsletter />
        <Footer />
      </>
    </div>
  );
};

export const getServerSideProps = async ({ req }) => {
  if (req.headers.cookie) {
    headers.cookie = req.headers.cookie;
  }

  try {
    const res = await axios.get(`${serverAPI}/blogs?page=1&limit=3`, {
        withCredentials: true,
        headers: {
            ...headers,
        },
    });

    let developers = [];
    try {
        let res = await axios.get(`${serverAPI}/off-plan/plan-developer`);
        if (res.data.length && res.data.length < 1) {
            return { props: { developers } };
        }
        let promises = res.data.filter(e => e.logo).map(async (e) => {
            return developers.push(e);
        });
        await Promise.all(promises);
    } catch (e) {
        console.log(e);
    }

    return {
      props: {
        blogs: res?.data,
        developers,
      },
    };
  } catch (error) {
    // console.log(error)
    return {
      props: {
        blogs: [],
        developers: [],
        errors: JSON.stringify(error),
      },
    };
  }
};

export default Home;
