import Image from "next/image";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import { serverAPI, actionS3Objects } from "../utils/envConfig";
import Link from "next/link";

import
{
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/thumbs";
import S3Image from "./S3Image";

const ByCommunities = () =>
{
    const [ communityList, setCommunityList ] = useState( [] );
    const [ windowWidth, setWindowWidth ] = useState( null );

    const fetchCommunities = async ( pg ) =>
    {
        try
        {
            const { data } = await axios.get(
                `${ serverAPI }/community/withProperties?page=${ pg }`
            );
            data?.length && data.filter( e => e.icon ).sort( ( a, b ) => ( a.rank > b.rank ? -1 : 1 ) );
            // let limitedCommunity = reduceArrayLength(data);
            setCommunityList( data
                // await Promise.all(
                //     limitedCommunity?.map(async (item) => {
                //         const image = item?.icon
                //             ? await getImage(item?.icon)
                //             : "/img/placeholder.jpeg";
                //         // : null;
                //         return {
                //             ...item,
                //             image: image,
                //         };
                //     })
                // )
            );
        } catch ( err )
        {
            // console.log(err);
        }
    };

    const updateWindowWidth = ( e ) =>
    {
        setWindowWidth( window.innerWidth );
    };

    useEffect( () =>
    {
        fetchCommunities();

        setWindowWidth( window?.innerWidth );

        window.addEventListener( "resize", updateWindowWidth );

        return () =>
        {
            window.removeEventListener( "resize", updateWindowWidth );
        };
    }, [] );

    return ( windowWidth && windowWidth ) < 768 ? (
        <div className="mt-8 sm:my-6">

            { communityList && communityList.length > 4 && (
                <div>
                    <div className="flex flex-col justify-start items-start">
                        
                
                    <h6 className="text-navy font-Lexend mx-10 mt-6">
                        <span className="font-bold 3xl:pl-0 4xs:text-2xl 3xl:text-3xl ">
                            Properties by</span>

                        <span className="4xs:text-2xl 3xl:text-3xl"> { "" }Community</span>
                    </h6>
                        <p className="mx-10 sm:text-lg text-left text-[#99700D] font-Lexend pt-2">
                        Refine your search by locating the best property in
                        every community in Dubai
                        </p>
                    </div>
                    <div className="">
                        <Swiper
                            className="swiper-2 bg-white"
                            // install Swiper modules
                            modules={ [
                                Autoplay,
                                Navigation,
                                Pagination,
                                Scrollbar,
                                A11y,
                            ] }
                            spaceBetween={ 0 }
                            speed={ 1000 }
                            loop={ true }
                            slidesPerView={ 2 }
                            navigation
                            pagination={ { clickable: true } }
                            breakpoints={ {
                                200: { slidesPerView: 1 },
                                500: { slidesPerView: 2 },
                                1300: { slidesPerView: 2 },
                            } }
                            autoplay={ {
                                delay: 3000,
                                disableOnInteraction: false,
                                reverseDirection: false,
                            } }
                        >
                            { communityList?.map( ( item ) => (
                                <SwiperSlide key={ item?.id }>
                                    <Link
                                        href={ `/find-the-properties/dubai/${ item?.slug
                                            }` }
                                    >
                                        <a className="mx-2 my-4 border border-neutral-200 shadow-customnavy rounded-md !overflow-hidden grid">
                                            <div className="max-h-[230px] w-full overflow-hidden">
                                                <S3Image
                                                    url={ item?.icon }
                                                    width={ 800 }
                                                    height={ 533 }
                                                    alt={ item?.community }
                                                    className="absolute h-full w-full object-cover object-center"
                                                />
                                            </div>
                                            <div className="pb-2 font-bold truncate text-center font-Lexend pt-2 text-navy">
                                                { item?.community }
                                            </div>
                                        </a>
                                    </Link>
                                </SwiperSlide>
                            ) ) }
                        </Swiper>
                    </div>
                </div>
            ) }
            <div className="w-max mx-auto">
                <Link href="/find-the-properties" passHref>
                    <a className="px-6 text-center py-2.5 text-xl bg-[#DFC090] rounded-full text-navy
                     hover:bg-night  hover:text-[#FFDEAB] relative block font-bold overflow-hidden">
                        <span>Explore More</span>
                    </a>
                </Link>
            </div>
        </div>
    ) : (
        <div
            className={ `lg:container lg:mx-auto mx-4 px-4 3xl:w-[1500] overflow-hidden h-full ${ communityList && communityList.length > 4
                ? "my-10 sm:my-14"
                : "my-5 sm:my-7"
                }` }
        >
            { communityList && communityList.length > 4 && (
                <div className="flex flex-col justify-center 
                justify-items-center items-center gap-y-2 mt-12
                 bg-gray-100  rounded-2xl h-full ">
                    <h3 className=" text-navy font-Lexend justify-start">
                        <span className="font-bold 3xl:pl-0 4xs:text-2xl 3xl:text-3xl">
                            Properties
                        </span>
                        <span className="4xs:text-2xl 3xl:text-3xl">
                            { "" } by Community
                        </span>

                    </h3>
                    <p className="text-lg text-center text-[#99700D]">
                        Refine your search by locating the best property in
                        every community in Dubai
                    </p>



                    {/* start first swiper slider  */ }
                    { communityList && communityList.length > 4 &&
                        <Swiper
                            modules={ [ Autoplay, Navigation, Pagination, Scrollbar, A11y ] }

                            speed={ 5000 }
                            loop={ true }
                            breakpoints={ {

                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                },
                                880: {
                                    slidesPerView: 5,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                },
                                1024: {
                                    slidesPerView: 5.5,
                                    spaceBetween: 20,
                                    imageSize: 30,
                                },
                                1280: {
                                    slidesPerView: 6,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                },
                                1536: {
                                    slidesPerView: 7,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                }
                                ,
                                1776: {
                                    slidesPerView: 6.5,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                }
                                ,
                                2800: {
                                    slidesPerView: 8,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                }


                            } }

                            navigation
                            pagination={ { clickable: true } }
                            rewind={ true }
                            autoplay={ {
                                delay: 100,
                                disableOnInteraction: false,
                                reverseDirection: true,
                                pauseOnMouseEnter: true,
                            } }
                            style={ { width: '100%' } }
                            className="my-10"
                        >
                            { communityList?.slice( 0, 10 )?.map(
                                ( community, i ) =>
                                ( <SwiperSlide key={ i }>
                                    <a href={ `/find-the-properties/dubai/${ community?.slug
                                        }` } className="flex flex-col gap-2
                                         items-center text-center justify-center 
                                          cursor-pointer  duration-100 ease-linear 
                                          transition-all text-xs lg:text-base 
                                          overflow-x-hidden ">
                                        <div className="relative 3xs:h-[6rem] 3xs:w-[9rem] 
                                         sm:h-[4rem] sm:w-[7rem]  md:h-[9rem] md:w-[9rem] lg:h-[7rem] lg:w-[10rem] xl:h-[8rem] xl:w-[11rem]
                                         3xl:h-[8rem] 3xl:w-[16rem] 6xl:w-[20rem] 6xl:h-[11rem]">
                                            <div className="absolute object-fill object-center h-full rounded-2xl w-full" alt="placeholder" style={ { width: '100%' } } >
                                                <S3Image url={ community?.icon }
                                                    className="rounded-xl" />
                                            </div>
                                        </div>
                                        <p className="w-full truncate text-center text-navy font-Lexend font-semibold">
                                            { community?.community }
                                        </p>
                                    </a>
                                </SwiperSlide> ) )
                            }

                        </Swiper>
                    }
                    {/* start second swiper slider  */ }
                    { communityList && communityList.length > 16 &&
                        <Swiper
                            modules={ [ Autoplay, Navigation, Pagination, Scrollbar, A11y ] }
                            speed={ 5000 }
                            loop={ true }
                            breakpoints={ {

                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                },
                                880: {
                                    slidesPerView: 5,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                },
                                1024: {
                                    slidesPerView: 5.5,
                                    spaceBetween: 20,
                                    imageSize: 30,
                                },
                                1280: {
                                    slidesPerView: 6,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                }
                                ,
                                1536: {
                                    slidesPerView: 7,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                },
                                1776: {
                                    slidesPerView: 6.5,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                }
                                ,
                                2800: {
                                    slidesPerView: 8,
                                    spaceBetween: 10,
                                    imageSize: "100%",
                                }


                            } }
                            navigation
                            pagination={ { clickable: true } }
                            autoplay={ {
                                delay: 100,
                                disableOnInteraction: false,
                                reverseDirection: false,
                                pauseOnMouseEnter: true,
                            } }
                            style={ { width: '100%' } }
                            className="mb-6"
                        >
                            { communityList?.slice( 10, 20 )?.map(
                                ( community, i ) =>
                                ( <SwiperSlide key={ i }>
                                    <a href={ `/find-the-properties/dubai/${ community?.slug
                                        }` } className="flex flex-col gap-2
                                         items-center text-center justify-center 
                                          cursor-pointer  duration-100 ease-linear 
                                          transition-all text-xs lg:text-base 
                                          overflow-x-hidden ">
                                        <div className="relative 3xs:h-[6rem] 3xs:w-[9rem] 
                                         sm:h-[4rem] sm:w-[7rem]  md:h-[9rem] md:w-[9rem] lg:h-[7rem] lg:w-[10rem] xl:h-[8rem] xl:w-[11rem]
                                         3xl:h-[8rem] 3xl:w-[16rem] 6xl:w-[20rem] 6xl:h-[11rem]">
                                            <div className="absolute object-fill object-center h-full rounded-2xl w-full" alt="placeholder" style={ { width: '100%' } } >
                                                <S3Image url={ community?.icon } className="rounded-xl" />
                                            </div>
                                        </div>
                                        <p className="w-full truncate text-center text-navy font-Lexend font-semibold">
                                            { community?.community }
                                        </p>
                                    </a>
                                </SwiperSlide> ) ) }

                        </Swiper>
                    }
                    <Link href="/find-the-properties" passHref>
                        <a className=" py-3 px-7 text-xl hover:bg-night rounded-full font-Lexend
                         hover:text-[#FFDEAB] my-3   bg-[#DFC090]  text-navy font-semibold relative block  overflow-hidden">
                            <span>Explore More</span>
                        </a>
                    </Link>
                </div>
            ) }
        </div>
    );
};

export default ByCommunities;
