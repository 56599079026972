import React, { useMemo, useState } from "react";
import { publicS3Bucket } from "../utils/envConfig";
import Image from "next/image";

export default function S3Image({ url, alt, width, height, className }) {
  const imageSrc = useMemo(
    () => (url ? `https://${publicS3Bucket}/${url}` : "/img/placeholder.jpeg"),
    [url]
  );
  const [src, setSrc] = useState(imageSrc);

  return (
    <div>
      {url && (
        <Image
          alt={alt}
          src={src}
          width={width}
          height={height}
          quality={50}
          className={className}
          objectFit="cover"
          loading="lazy"
          placeholder="blur"
          onError={() => setSrc("/img/placeholder.jpeg")}
          blurDataURL="/img/placeholder.jpeg"
          objectPosition="center"
          layout={width && height ? "responsive" : "fill"}
        ></Image>
      )}
    </div>
  );
}
